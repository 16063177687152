// 300 x 300
import human from '../assets/images/races/human.webp';
import hobbit from '../assets/images/races/hobbit.webp';
import dwarf from '../assets/images/races/dwarf.webp';
import orc from '../assets/images/races/orc.webp';
import troll from '../assets/images/races/troll.webp';
import elf from '../assets/images/races/elf.webp';

// 300 x 300
import warrior from '../assets/images/classes/warrior.webp'
import deathKnight from '../assets/images/classes/dk.webp'
import mage from '../assets/images/classes/mage.webp'
import wizard from '../assets/images/classes/wizard.webp'
import priest from '../assets/images/classes/priest.webp'
import paladin from '../assets/images/classes/paladin.webp'
import hunter from '../assets/images/classes/hunter.webp'
import rogue from '../assets/images/classes/rogue.webp'
import necromancer from '../assets/images/classes/necromancer.webp'
import druid from '../assets/images/classes/druid.webp'

// 300 x 300
import good from '../assets/images/alignments/good.webp'
import evil from '../assets/images/alignments/evil.webp'
import neutral from '../assets/images/alignments/neutral.webp'

// 256 x 256
import skeleton from '../assets/images/creatures/minions/skeleton.webp'
import zombie from '../assets/images/creatures/minions/zombie.webp'
import golem from '../assets/images/creatures/minions/golem.webp'

import sage from '../assets/images/creatures/minions/oak-sage.webp'
import hamster from '../assets/images/creatures/minions/hamster.webp'
import gopher from '../assets/images/creatures/minions/gopher.webp'
import wildcat from '../assets/images/creatures/minions/wildcat.webp'
import porcupine from '../assets/images/creatures/minions/porcupine.webp'
import mongoose from '../assets/images/creatures/minions/mongoose.webp'
import beaver from '../assets/images/creatures/minions/beaver.webp'
import raccoon from '../assets/images/creatures/minions/raccoon.webp'
import wolverine from '../assets/images/creatures/minions/wolverine.webp'
import panda from '../assets/images/creatures/minions/panda.webp'
import panther from '../assets/images/creatures/minions/panther.webp'
import bear from '../assets/images/creatures/minions/bear.webp'

// 128 x 128
import attack from '../assets/images/abilities/attack.webp'
import shootWand from '../assets/images/abilities/shoot-wand.webp'
import claw from '../assets/images/abilities/claw.webp'
import cleave from '../assets/images/abilities/cleave.webp'
// warrior
import warCry from '../assets/images/abilities/warrior/war-cry.webp'
import mortalStrike from '../assets/images/abilities/warrior/mortal-strike.webp'
import agileStyle from '../assets/images/abilities/warrior/agile-style.webp'
import sharpenWeapon from '../assets/images/abilities/warrior/sharpen-weapon.webp'
import kick from '../assets/images/abilities/warrior/kick.webp'
import maim from '../assets/images/abilities/warrior/maim.webp'
// mage
import fireball from '../assets/images/abilities/mage/fireball.webp'
import fireBomb from '../assets/images/abilities/mage/firebomb.webp'
// druid
import callBeast from '../assets/images/abilities/druid/call-beast.webp'
import rejuvenation from '../assets/images/abilities/druid/rejuvenation.webp'
import spiritBond from '../assets/images/abilities/druid/spirit-bond.webp'
import oakSage from '../assets/images/abilities/druid/oak-sage.webp'
import natureStrike from '../assets/images/abilities/druid/nature-strike.webp'
import barkSkin from '../assets/images/abilities/druid/bark-skin.webp'
import cure from '../assets/images/abilities/druid/cure.webp'
// necromancer
import raiseMinion from '../assets/images/abilities/necromancer/raise-minion.webp'
// death knight

// 128 x 128
import stun from '../assets/images/buffs/stun.webp'
import freeze from '../assets/images/buffs/freeze.webp'
import death from '../assets/images/death.webp'

const raceAssets = {human, hobbit, orc, troll, dwarf, elf}
const classAssets = {
  warrior,
  'death knight': deathKnight,
  mage,
  wizard,
  priest,
  paladin,
  hunter,
  rogue,
  necromancer,
  druid,
}

const alignmentAssets = {
  align0: good,
  align1: evil,
  align2: neutral,
}

const minionAssets = {
  // necromancer
  Skeleton: skeleton,
  Zombie: zombie,
  Golem: golem,
  // druid
  'Oak Sage': sage,
  Hamster: hamster,
  Gopher: gopher,
  Wildcat: wildcat,
  Porcupine: porcupine,
  Mongoose: mongoose,
  Beaver: beaver,
  Raccoon: raccoon,
  Wolverine: wolverine,
  Panda: panda,
  Panther: panther,
  Bear: bear,
}

const abilityAssets = {
  attack: attack,
  'shoot wand': shootWand,
  claw: claw,
  cleave: cleave,

  // warrior
  'war cry': warCry,
  'mortal strike': mortalStrike,
  'agile style': agileStyle,
  'sharpen weapon': sharpenWeapon,
  kick: kick,
  maim: maim,

  // mage
  fireball: fireball,
  firebomb: fireBomb,

  // druid
  'call beast': callBeast,
  rejuvenation: rejuvenation,
  'spirit bond': spiritBond,
  'oak sage': oakSage,
  'nature strike': natureStrike,
  'bark skin': barkSkin,
  cure: cure,

  // necromancer
  'raise minion': raiseMinion,

  // death knight
}

const buffAssets = {
  stun: stun,
  freeze: freeze,
}

const commonAssets = {
  death: death,
}

export {commonAssets, raceAssets, classAssets, alignmentAssets, minionAssets, abilityAssets, buffAssets}
